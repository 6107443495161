import {Component, OnDestroy} from '@angular/core';
import {ActivityService} from '@app/core/services/activity.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {ActivityResponse} from '@app/core/models';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {ActivityStatus} from '@app/core/constants/activity-status';

@Component({
    selector: 'app-appliance-hierarchy-mv-refresh',
    templateUrl: './appliance-hierarchy-mv-refresh.component.html'
})
export class ApplianceHierarchyMvRefreshComponent implements OnDestroy {

    private static readonly PASSIVE_STATUSES: ActivityStatus[] = [ActivityStatus.SUCCESS, ActivityStatus.FAILED, ActivityStatus.CANCELLED];

    private readonly ngDestroy = new Subject<void>();

    private readonly stateSubject = new BehaviorSubject<ActivityResponse | undefined>(undefined);
    public readonly state$ = this.stateSubject.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly enabled$ = this.state$
        .pipe(takeUntil(this.ngDestroy), map(x => !x || ApplianceHierarchyMvRefreshComponent.PASSIVE_STATUSES.includes(x.status)));
    private interval?: number;

    public constructor(
        private activityService: ActivityService,
        private toastr: ToastrService
    ) {
        this.updateState();
    }

    private set state(state: ActivityResponse) {
        this.stateSubject.next(state);
        if (!state || ApplianceHierarchyMvRefreshComponent.PASSIVE_STATUSES.includes(state.status)) {
            this.stopInterval();
        } else {
            this.startInterval();
        }
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public submit(): void {
        this.activityService.updateMaterializedView('APPLIANCE_HIERARCHY').pipe(takeUntil(this.ngDestroy)).subscribe({
            next: state => this.state = state,
            error: __ => this.toastr.error('Seadmete hierarhia värskendamise päringu viga!')
        });
    }

    private updateState(): void {
        this.activityService.getState('APPLIANCE_HIERARCHY_MV_REFRESH').pipe(takeUntil(this.ngDestroy)).subscribe({
            next: state => this.state = state,
            error: __ => this.toastr.error('Seadmete hierarhia värskendamise oleku päringu viga!')
        });
    }

    private startInterval(): void {
        if (!!this.interval) return;

        this.interval = window.setInterval(() => this.updateState(), 1000);
    }

    private stopInterval(): void {
        if (!this.interval) return;

        window.clearInterval(this.interval);
        this.interval = undefined;
    }
}
