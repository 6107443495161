export namespace MetadataDto {

    export class PageResponse<T> {

        public metadata: PageMetaDataResponse;
        public data: T[];
    }

    export class PageMetaDataResponse {

        public page: number;
        public offset?: number;
        public limit: number;
        public count: number;
        public sort: string;
        public direction: 'ASC' | 'DESC';
        public search: string;
    }

    export abstract class StatsResponse {

        public count: number;
    }

    export abstract class PagedRequest {

        public offset?: number;
        public limit?: number;
        public sortFields?: string;
        public sort?: string;
        public sortField?: string;
    }

    export class IdAndCode {

        public id: number;
        public code: string;
        public type: string;
        public identification: string;
        public information: string;
    }

    export class IdAndName {

        public id: number;
        public name: string;
    }

    export class IdCodeAndName {

        public id: number;
        public code: string;
        public name: string;
    }

    export class IdCodeNameAndAddress extends IdCodeAndName {

        public address: string;
    }

    export class IdCodeAndType extends IdAndCode {

        public type: string;
    }

    export class IdCodeNameAndType extends IdCodeAndName {

        public type: string;
    }

    export class IdAndDate {

        public id: number;
        public date: Date;
    }
}
