import {Moment} from 'moment';

export class YearMonth {

    public readonly year: number;
    public readonly month: number;
    public readonly asString: string;

    private constructor(year: number, month: number) {
        this.year = year;
        this.month = month;
        this.asString = `${year.toString(10).padStart(4, '0')}-${month.toString(10).padStart(2, '0')}`;
    }

    public static from(aMoment: Moment): YearMonth {
        if (!aMoment?.isValid()) throw new Error('YearMonth must be valid!');

        return new YearMonth(aMoment.year(), aMoment.month() + 1);
    }
}
